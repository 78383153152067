import { useQuery } from '@tanstack/react-query';
import requests from 'service/api';
import SearchSelect from '../search-select';
import { useEffect, useState } from 'react';
import { useFormikContext } from 'formik';

export function SelectReferanceCode3({ name }) {
  const { values } = useFormikContext();
  const [selected, setSelected] = useState(null);

  const { data = [], isFetched } = useQuery({
    queryKey: ['/list', 'referance'],
    queryFn: async () => {
      try {
        const { data } = await requests.referance.list();
        console.log('Gelen Data:', data); // Gelen veriyi konsola yazdır
        return data;
      } catch (error) {
        console.error('API Error:', error);
        return []; // Hata durumunda boş bir dizi döndür
      }
    },
    placeholderData: [], // Başlangıçta boş bir dizi
  });

  const isWasgingLabel = Array.isArray(data)
    ? data.filter((item) => item.labelCategory === 'FİYAT KARTI')
    : [];

  const options = isWasgingLabel.map(({ id, code }) => ({
    value: id,
    label: code,
  }));

  useEffect(() => {
    console.log('Formik Values:', values); // Formik values değerini konsola yazdır
    if (values?.priceLabel?.[0]?.referanceCode && isFetched) {
      const select = options.find(({ value }) => value === values.priceLabel[0].referanceCode);
      setSelected(select || null); // Eşleşme bulunamazsa null olarak ayarla
    }
    // eslint-disable-next-line
  }, [values, isFetched]);

  return (
    <SearchSelect
      name={name}
      label="Referans Kodu"
      options={options}
      value={selected || null} // `selected` boşsa null döndür
      onChange={(e) => setSelected(e)}
    />
  );
}
